
interface Props {
  name: string
  url: string
}

export default {
  props: ["name", "url"]
  // setup(props: Props) {
  //   return {
  //     name: props.name,
  //     url: props.url
  //   }
  // }
}
